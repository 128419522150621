import { graphql, Link } from "gatsby"
import React from "react"
import useRoutePath from "../../../hooks/useRoutePath"
import useWebsite from "../../../hooks/useWebsite"
import Button from "../../atoms/Button"
import TileLayout from "../../layouts/TileLayout"
import DatoImage from "../DatoImage"
import * as styles from "./DatoProductFinderLinkBar.module.scss"

const DatoProductFinderLinkBar = (props: Queries.DatoProductFinderLinkBarFragment) => {
  //console.log('Rendering DatoProductFinderLinkBar', props)

  const website = useWebsite()
  const getRoutePath = useRoutePath()
  const path = getRoutePath(website.productRootRoute?.originalId)

  return (
    <div>
      <TileLayout columns={4}>
        {props.productFinderLinks?.map((link, i) => {
          if (!link) return null
          const query = link.searchQuery || ""
          return (
            <Link key={i} to={path + query} className={styles.link}>
              {link.image && <DatoImage className={styles.image} {...link.image} alt={link.image.alt || ""} />}
              <span className={styles.label}>
                <Button label={link.title} tabIndex={-1} />
              </span>
            </Link>
          )
        })}
      </TileLayout>
    </div>
  )
}

export default DatoProductFinderLinkBar

export const query = graphql`
  fragment DatoProductFinderLinkBar on DatoCmsProductFinderLinkBar {
    id: originalId # Korjaus daton bugiin, jos käytetään structured textissä
    productFinderLinks {
      image {
        ...DatoImageDefault
      }
      title
      searchQuery
    }
  }
`
